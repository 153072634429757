<template>
  <div>
    <b-card title="Ekle">
      <b-form-group
        label="Lastik Ebatı"
        label-for="id_com_tire_size"
      >
        <v-select
          id="id_com_tire_size"
          v-model="tireHotel.id_com_tire_type"
          placeholder="Lastik Ebatı"
          label="title"
          :options="tireSizes"
          :reduce="tireSizes => tireSizes.id"
        />
      </b-form-group>
      <b-form-group
        label="Lastik Markası"
        label-for="id_com_tire_brand"
      >
        <v-select
          id="id_com_tire_brand"
          v-model="tireHotel.id_com_tire_brand"
          placeholder="Lastik Markası"
          label="title"
          :options="tireBrands"
          :reduce="tireBrands => tireBrands.id"
        />
      </b-form-group>
      <b-form-group
        label="Lastik Tipi"
        label-for="id_com_tire_type"
      >
        <v-select
          id="id_com_tire_type"
          v-model="tireHotel.id_com_tire_type"
          placeholder="Lastik Tipi"
          label="title"
          :options="tireTypes"
          :reduce="tireTypes => tireTypes.id"
        />
      </b-form-group>
      <b-form-group
        label="Raf"
        label-for="id_com_tire_shelf"
      >
        <v-select
          id="id_com_tire_shelf"
          v-model="tireHotel.id_com_tire_shelf"
          placeholder="Raf"
          label="title"
          :options="tireShelfs"
          :reduce="tireShelfs => tireShelfs.id"
        />
      </b-form-group>
      <b-form-group
        label="Teknisyen"
        label-for="id_com_technician"
      >
        <v-select
          id="id_com_technician"
          v-model="tireHotel.id_com_technician"
          placeholder="Teknisyen"
          label="name"
          :options="users"
          :reduce="users => users.id"
        />
      </b-form-group>
      <b-col
        cols="12"
      >
        <b-button variant="danger">
          Kaydet
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BCol, BCard, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Add',
  components: {
    BCol,
    BCard,
    BFormGroup,
    BButton,
    vSelect,
  },
  computed: {
    tireSizes() {
      return this.$store.getters['tireSizes/getTireSizes']
    },
    tireBrands() {
      return this.$store.getters['tireBrands/getTireBrands']
    },
    tireTypes() {
      return this.$store.getters['tireTypes/getTireTypes']
    },
    tireShelfs() {
      return this.$store.getters['tireShelfs/getTireShelfs']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    tireHotel() {
      return this.$store.getters['tireHotel/dataItem']
    },
    getDataItem() {
      return this.$store.getters['cars/getCar']
    },
  },
  created() {
    this.getTireSizes()
    this.getTireBrands()
    this.getTireTypes()
    this.getTireShelfs()
    this.getUsers()
    this.getData()
    this.getTireHotel()
  },
  methods: {
    getTireSizes() {
      this.$store.dispatch('tireSizes/tireSizesList')
    },
    getTireBrands() {
      this.$store.dispatch('tireBrands/tireBrandsList')
    },
    getTireTypes() {
      this.$store.dispatch('tireTypes/tireTypesList')
    },
    getTireShelfs() {
      this.$store.dispatch('tireShelfs/tireShelfsList')
    },
    getUsers() {
      this.$store.dispatch('users/usersList')
    },
    getTireHotel() {
      this.$store.dispatch('tireHotel/getDataList')
    },
    getData() {
      this.$store.dispatch('cars/carView', this.$route.params.cars_id)
    },
  },
}
</script>
